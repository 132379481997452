.forgot-password-container {
  height: 100vh;
  display: flex;

  h2 {
    margin-top: 10rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .forgot-password-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 30rem;
    margin-left: auto;
    margin-right: auto;

    .input-field {
      margin-top: 1rem;
    }

    .register-btn {
      margin-top: 1rem;
      margin-left: auto;
      margin-right: auto;
      min-width: 10rem;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
      border-radius: 1.5rem;
      color: white;
      border-color: white;
      border-width: 1px;
      outline: none;
    }

    .register-btn-enabled {
      background: rgb(30, 144, 255);
    }

    .register-btn-disabled {
      background: rgb(139, 176, 255);
      cursor: not-allowed;
    }
  }
}